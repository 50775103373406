'use stric'

import React from 'react'
import { graphql, Link } from 'gatsby'
import _ from 'lodash'
import classNames from 'classnames'

import ReactMarkdown from 'markdown-to-jsx'

import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import { siteTheme } from '../components/layout/theme'
import Layout from '../components/layout/Layout'

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  pageContainer: {
    paddingTop: '140px',
    paddingBottom: '80px'
  },
  pageGrid: {
    flexGrow: 1
  },
  imgLogoContainer: {
    // padding: '0px 0px 24px 0px'
  },
  imgLogo: {
    maxWidth: '120px',
    maxHeight: '120px',
    width: '100%',
    height: 'auto'
  },
  h1: {
    // fontSize: '40px',
    // fontWeight: 'bold',
    // lineHeight: '44px',
    // color: siteTheme.color3
    padding: '24px 0px'
  },
  h2: {
    padding: '32px 0px 16px 0px',
    '&:first-child': {
      padding: '0px 0px 16px 0px'
    }
  },
  h3: {
    padding: '16px 0px'
  },
  subtitle1: {
    padding: '16px 0px'
  },
  caption: {
    padding: '16px 0px'
  },
  paragraph: {
    margin: `${theme.spacing.unit}px 0px`,
    textAlign: 'justify'
  },
  ul: {
    listStyle: 'none',
    margin: `${theme.spacing.unit}px 0px`,
    padding: 0
  },
  li: {
    // paddingLeft: `${theme.spacing.unit * 2}px`,
    marginLeft: '1.5em',
    marginBottom: theme.spacing.unit,
    textAlign: 'justify',
    '&::before': {
      content: '"-"',
      display: 'inline-block',
      width: '1.5em',
      marginLeft: '-1.5em'
      // paddingRight: `${theme.spacing.unit * 2}px`
    }
  },
  // imageContainer: {
  //   padding: '0px 0px 24px 24px',
  //   float: 'right',
  //   // textAlign: 'center'
  //   [theme.breakpoints.down('md')]: {
  //     padding: '0px 0px 24px 0px'
  //   }
  // },
  contentContainer: {
    [theme.breakpoints.up('sm')]: {
      marginRight: '150px'
    }
  },
  image: {
    margin: '0px 0px 24px 0px',
    float: 'none',
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '350px',
      margin: '0px 0px 24px 24px',
      marginRight: '-150px',
      float: 'right',
      boxShadow: siteTheme.boxShadow2
    }
  },
  advertiseContainer: {
    background: siteTheme.color5,
    padding: '75px 0px'
  },
  advertiseTitle: {
    color: siteTheme.color1,
    fontSize: '40px',
    fontWeight: 'bold',
    lineHeight: '44px',
    textAlign: 'center',
    padding: '25px 0px'
  },
  advertiseMessage: {
    color: siteTheme.color8,
    fontSize: '16px',
    lineHeight: '28px',
    textAlign: 'center',
    padding: '25px 0px',
    maxWidth: '800px',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  advertiseButtonContainer: {
    margin: '25px auto',
    textAlign: 'center'
  },
  advertiseButton: {
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`,
    boxShadow: siteTheme.boxShadow4,
    textTransform: 'none'
  }
})

const mdOptions = {
  forceBlock: true,
  overrides: {
    h1: {
      component: withStyles(styles)(({ id, classes, children, ...props }) => (
        <Typography variant='h2' className={classes.h2} id={id}>
          { children }
        </Typography>
      ))
    },
    h2: {
      component: withStyles(styles)(({ id, classes, children, ...props }) => (
        <Typography variant='h3' className={classes.h3} id={id}>
          { children }
        </Typography>
      ))
    },
    h3: {
      component: withStyles(styles)(({ id, classes, children, ...props }) => (
        <Typography variant='subtitle1' className={classes.subtitle1} id={id}>
          { children }
        </Typography>
      ))
    },
    h4: {
      component: withStyles(styles)(({ id, classes, children, ...props }) => (
        <Typography variant='caption' className={classes.caption} id={id}>
          { children }
        </Typography>
      ))
    },
    p: {
      component: withStyles(styles)(({ classes, children, ...props }) => (
        <Typography variant='body2' className={classes.paragraph}>
          { children }
        </Typography>
      ))
    },
    // Generate span for paragraphs ??
    span: {
      component: withStyles(styles)(({ classes, children, ...props }) => (
        <Typography variant='body2' className={classes.paragraph}>
          { children }
        </Typography>
      ))
    },
    ul: {
      component: withStyles(styles)(({ classes, children, ...props }) => (
        <ul className={classes.ul}>
          { children }
        </ul>
      ))
    },
    li: {
      component: withStyles(styles)(({ classes, children, ...props }) => (
        <li className={classes.li}>
          <Typography variant='body2' inline children={children} />
        </li>
      ))
    }
  }
}

class PageTemplate extends React.Component {
  render () {
    const {
      data,
      classes
    } = this.props
    const { strapiWebpage } = data
    const {
      // id,
      // name,
      label,
      path,
      title,
      heading,
      description,
      imgLogo,
      content,
      image,
      webSection
    } = strapiWebpage

    // image is optional
    let imageSrc
    // if (image && image.childImageSharp && image.childImageSharp.fixed) {
    //   imageSrc = image.childImageSharp.fixed.src
    // }
    if (image && image.url) {
      imageSrc = image.url
    }
    let imgLogoSrc
    if (imgLogo && imgLogo.url) {
      imgLogoSrc = imgLogo.url
    }

    // Section
    const sectionName = _.isNil(webSection) ? undefined : webSection.name

    return (
      <Layout activeSection={sectionName} title={label} description={description} image={imageSrc} pathname={path}>
        <div className={classes.layout}>
          <div className={classes.pageContainer}>
            <div className={classes.imgLogoContainer}>
              {
                imgLogoSrc &&
                  <img src={imgLogoSrc} className={classes.imgLogo} />
              }
            </div>
            <Grid container
              direction='row'
              justify='space-between'
              alignItems='flex-start'
              alignContent='stretch'
              spacing={40}
              className={classes.pageGrid}
            >
              <Grid item xs={12} sm={12} md={3}>
                <Typography variant='h1' className={classes.h1}>
                  {title}
                </Typography>
                <Typography variant='subtitle1' className={classes.subtitle1}>
                  {heading}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={9}>
                <div className={classes.contentContainer}>
                  {
                    imageSrc &&
                      <img src={imageSrc} className={classes.image} />
                  }
                  <ReactMarkdown options={mdOptions}>
                    {content}
                  </ReactMarkdown>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        {
          sectionName === 'metiers' &&
          <div className={classes.advertiseContainer}>
            <div className={classes.layout}>
              <Typography variant='h2' className={classes.advertiseTitle}>
                Vous ne trouvez pas votre activité&nbsp;?
              </Typography>
              <Typography variant='body2' className={classes.advertiseMessage}>
                Si vous souhaitez souscrire un contrat d'assurance pour une entreprise exerçant une autre activité,
                vous pouvez spécifier votre besoin à travers notre formulaire de devis.
              </Typography>
              <div className={classes.advertiseButtonContainer}>
                <Button component={Link} to='/devis'
                  color='primary' variant='contained'
                  className={classes.advertiseButton}
                >
                  Obtenir mes tarifs
                </Button>
              </div>
            </div>
          </div>
        }

        {
          sectionName === 'assurances' &&
          <div className={classes.advertiseContainer}>
            <div className={classes.layout}>
              <Typography variant='h2' className={classes.advertiseTitle}>
                Vous souhaitez un autre type d'assurance&nbsp;?
              </Typography>
              <Typography variant='body2' className={classes.advertiseMessage}>
                Si vous cherchez un autre type de police d'assurance,
                vous pouvez spécifier votre besoin à travers notre formulaire de demande de devis.
              </Typography>
              <div className={classes.advertiseButtonContainer}>
                <Button component={Link} to='/devis'
                  color='primary' variant='contained'
                  className={classes.advertiseButton}
                >
                  Obtenir mes tarifs
                </Button>
              </div>
            </div>
          </div>
        }

      </Layout>
    )
  }
}

export default withStyles(styles)(PageTemplate)

export const pageQuery = graphql`
  query($id: String!) {
    strapiWebpage(id: { eq: $id }) {
      id
      name
      label
      path
      title
      heading
      description
      imgLogo {
        url
      }
      content
      image {
        url
      }
      webSection {
        id
        name
        label
        path
      }
    }
  }
`

// childImageSharp {
//   fixed {
//     src
//   }
// childImageSharp {
//   fixed {
//     src
//     ...GatsbyImageSharpFixed_withWebp
//   }
// }
